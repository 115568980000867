.select-tag-info {
  background: #192141;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 12px 40px 12px 20px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.051));
  border-radius: 0.25rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: 96.5%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAJCAYAAADtj3ZXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABMSURBVHgBnYyLCQAgCEQdqQ1zs0ZpFEsqCPLbgSCe7wER9TkVEuF/5nhBWgkJNsjBcwgJHvAqTIEKegIX1ARhUBC0FCgIEH4ywWL1A+dKnRQz9bNNAAAAAElFTkSuQmCC) !important;
}

.select-tag-info option {
  border-bottom: 1px solid rgba(49, 59, 101, 0.5) !important;
  padding: 15px 0px !important;
}

.tooltip-bt-info {
  position: relative;
}
.tooltip-bt-info .tooltip-icon-img {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.tooltip-bt-info .tooltip-icon-img svg {
  color: #5e6891;
}
.tooltip-bt-info .tooltip-icon-img svg:hover {
  color: #38d982;
}
.tooltip-bt-info .tooltip-item {
  background: #38d982;
  box-shadow: 0px 10px 30px rgb(56 217 130 / 25%);
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
}
.tooltip-bt-info .tooltip-item p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #192141;
  margin-bottom: 0px;
}
.tooltip-bt-info .tooltip-icon-img:hover ~ .tooltip-item {
  visibility: visible;
  opacity: 1;
}

/* Change peer group screen css*/
.change-group-info {
  background: #262D51;
  border-radius: 10px;
  padding: 5px 20px;
}
.change-group-info .change-group-content .top-bar-row {
  display: grid;
  grid-template-columns: auto 30px;
  margin-bottom: 10px;
}
.change-group-info .change-group-content .top-bar-row h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0px;
}
.change-group-info .change-group-content .top-bar-row h3 img {
  padding-right: 20px;
}
.align-right {
  text-align: right;
}
.btn-link {
  cursor: pointer;
}
.change-group-info .nav-tabs {
  position: relative;
  border-bottom: none;
  margin-bottom: 25px;
  column-gap: 20px;
}
.change-group-info .nav-tabs .nav-link {
  position: relative;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background-color: #313b65;
  border-color: #313b65;
  border-radius: 10px;
  padding: 6px 12px;
}
.change-group-info .nav-tabs .nav-link:hover,
.change-group-info .nav-tabs .nav-link.active {
  color: #131833;
  background-color: #38d982;
  border-color: #38d982;
}
.change-group-info .nav-tabs .nav-link.active::after {
  content: " ";
  width: 20px;
  height: 20px;
  border-bottom: 15px solid #1f2748;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid transparent;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin: 0px auto;
}
.change-group-info .tab-content {
  background: #1f2748;
  border-radius: 5px;
  padding: 20px;
  max-height: 375px;
  overflow: hidden;
  overflow-y: auto;
}
.font-size-20 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

.quick-links-boxs {
  background: #262d51;
  border-radius: 5px;
  /* padding: 30px 20px; */
  cursor: pointer;
  position: relative;
  left: 0px;
  top: 0px;
}
.quick-box-img {
  display: grid;
  align-items: center;
  justify-content: center;
  background: #141936;
  border-radius: 100px;
  height: 100px;
  max-width: 100px;
  margin: 0px auto;
  margin-bottom: 20px;
}
.quick-links-boxs h3.quick-h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #a5accb;
  margin-bottom: 0px;
}
.quick-links-boxs.active {
  background-color: #4bd981;
}
.quick-links-boxs.active h3.quick-h3 {
  color: #131833;
}
.overflow-changes-txt {
  height: 340px;
  padding-right: 20px;
}
.btn-solid-css {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #262d51;
  border-radius: 5px;
  border: none;
  padding: 20px 25px;
  display: block;
  width: 100%;
}
.btn-solid-css:hover {
  color: #131833;
  background-color: #38d982;
  border-color: #38d982;
}
.btn-group-info button.active {
  color: #131833;
  background-color: #38d982;
  border-color: #38d982;
}
.key-word-search-tab-content input#searchBox {
  background: rgb(25, 33, 65);
  padding: 10px 15px 10px 25px;
  color: #fff;
}
.key-word-search-tab-content span#basic-addon1 {
  background: rgb(16, 71, 80);
}
select.form-control {
  height: 100% !important;
}
/* end Change peer group screen css*/

.studies-container {
    height: 226px;
    overflow-y: auto;
    margin-left: -15px;
}
.studies-container1 {
    height: 172px;
    overflow-y: auto;
    margin-left: -44px;
    width: auto;
    margin-right: 0px;
    max-width: none;
}
.studies-container2{
    height: 221px; 
    overflow-y: auto;

}
.studies-container div{
    color: #A5ACCB;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    margin-top: 14px;
    margin-bottom: 14px;
}
.studies-container1 div{
    color: #A5ACCB;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    margin-top: 14px;
    /* margin-bottom: 14px; */
   
}
.studies-container div:hover{
    color: #38d881;
}
.studies-container1 div:hover{
    color: #38d881;
}
.studies-container div.active{
    color: #38d881;
    font-weight: 500;
}
.studies-container1 div.active{
    color: #38d881;
    font-weight: 500;
}
.active-studies-nav {
    background-color: transparent;
    border: solid 12px transparent;
    border-radius: 10px;
    margin-top: -19px;
    padding-bottom: 3%;
    position: relative;
}

.active-studies-nav > div {
    padding-right: 6px;
}

.active-studies-nav > div > p {
    margin-left: 2.5%;
    color: #FE648D;
}

.active-studies-nav  hr {
    background-color: #313B65;
    height: 3px;
    margin-left: -14px;
}

.active-studies-container {
    height: 160px;
    overflow-y: auto;
    margin-left: -15px;
    overflow-x: hidden;
}

.active-studies-container div:hover{
    color: #38d881;
}

.active-studies-container div.active{
    color: #38d881;
    font-weight: 500;
}

.active-studies-container .text-elipsed{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.active-studies-row.row {
    cursor: pointer;
    color: #A5ACCB;
    font-size: 16px;
    margin-left: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.active-studies-nav .open-book-icon {
    margin-left: -8%;
    margin-right: 10px;
}

.available-studies-nav {
    background-color: #1F2748;
    border: solid 12px #1F2748;
    border-radius: 10px;
    padding-top: 3%;
    padding-bottom: 3%;
    position: relative;
}

.available-studies-nav .search-icon {
    background-color: #38D98255;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 1%;
    padding-left: 1%;
}

.studies-horizontal-line {
    background-color: #313B65;
    height: 0.9px;
    margin: 2px -18px 10px -36px ;
}


/* start tooltip css */
.data-tooltip-info {
    position: absolute;
    right: 10px;
    /* z-index: 1; */
}

.data-tooltip-info span img {
    background: white;
    border-radius: 100px;
    max-width: 20px;
}

.data-tooltip-info span[data-title] {
position: relative;
    cursor: help;
    
}

.data-tooltip-info span[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
     top: 29px;
    right: 0;
    width: 200px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background: rgb(150, 148, 148);
    color: #fff;
    font-size: 16px;
    z-index: 99;
    
}



.data-tooltip-info span[data-title]:hover::after {
    content: '';
    position: absolute;
    top: 16px;
    right: 5px;
    display: inline-block;
    color: #fff;
    border: 8px solid transparent;
    border-bottom: 8px solid rgb(150, 148, 148);
    z-index: 1 !important;
   
}
span.test {
    z-index: 99 !important;
}
span.test1 {
    z-index: 9 !important;
}
/* end tooltip css */
.pr-42px{padding-right: 42px;}
.queued-studies-nav {
    background-color: #1F2748;
    border: solid 12px #1F2748;
    border-radius: 10px;
    padding-top: 3%;
    padding-bottom: 3%;
    position: relative;
}

.queued-studies-nav > div {
    padding-right: 6px;
}

.queued-studies-nav .book-with-plus-icon {
    background-color: #38D98255;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 1%;
    padding-left: 1%;
} 

.chart-container {
    position: relative;
}

.chart-background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #1F2748CC;
}

.chart-background-content {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: center;
   align-items: center;
   
}

.chart-background-content div {
    font-size: 20px;
    margin-top: 20px;
    width: 40%;
    text-align: center;
    cursor: pointer;
}

.chart-background-content div:hover {
    text-decoration: underline;
}

.subscribe-button {
    background-color: #4368F6;
    color: white;
    width: 190px;
    height: 48px;
    border: none;
    border-radius: 5px;
}

.progress-bar-container {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #1F2748;
    border-radius: 5px;
    border: none;
}

.progress-bar-container.negative {
    border: 1px solid #A54D72;
}

.progress-bar-component{
    position: absolute;
    top: -1px;
    left: -1px;
    height: 10px;
    background-color: #38D982;
    border-radius: inherit;
}

.progress-bar-container.negative div.progress-bar-component{
    position: absolute;
    top: -1px;
    right: -1px;
    left: unset;
}

#user-progres-bar .progress-bar-component {
    background-color: #38D982;
}

#industry-progres-bar .progress-bar-component {
    background-color: #6585ff;
}

html,
body,
#root,
.App,
.content {
    height: 100%;
    width: 100%;
}

@media only screen and (min-width: 480px) {
    body{
        max-width: 2023px;
        min-width: 1280px;
        overflow: auto;
    }

}

@media only screen and (max-width: 480px) {

    .form-mb{
        margin: 10% !important;
    }
}
.content {
    background-image: url("./Styleguide/bg-img-full.png");
    overflow-y: auto;
    background-size: cover;
    /*width: 1600px;*/
    /*height: 1080px;*/

}


body {
    background-color: #141733 !important;
    background-repeat: repeat-y;
}

.App {
    color: white;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

/*.content {*/
/*	padding: 40px 20px;*/
/*}*/

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.vl {
    border-left: 6px solid green;
    height: 500px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
}

.greenMainColour {
    color: #38d881;
    font-size: xxx-large;
}

.navbarColour {
    color: #232d53;
}

.lightBlueColour {
    color: #6585ff;
}

.pinkColour {
    color: #b11dd6;
}

.darkBlueColour {
    color: #161d3a;
}

.cardColour {
    color: #1f2748;
}

.quickLinkCardColour {
    color: #313b65;
}

.backgroundColor {
    color: #1a2141;
}

/* ----------------- scroll bar color -----------------*/
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #313b65;
    width: 12px;
}

::-webkit-scrollbar-thumb {
    /*background: #1F2748;*/
    background: #636981;
    width: 4px;
    border-radius: 20px;
}

/* ----------------- scroll bar color -----------------*/

/* ----------------- Style the tab ----------------- */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

/* ----------------- Style the tab ----------------- */

/* quick links start*/
.quickLinkCircleBackGround {
    position: relative;
    top: 0;
    left: 0;
}

.quickLinkIcon {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    filter: invert(74%) sepia(4%) saturate(1536%) hue-rotate(192deg) brightness(91%) contrast(98%);
    height: 3rem;
}   

basicHover:hover {
    cursor: pointer;
}

.centerItem {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

onHover:hover {
    background-color: #38d982;
    color: #131833;
    cursor: pointer;
}

/* quick links end */

/* UTILITIES */
.c-pointer {
    cursor: pointer;
}

noPadding {
    padding: 0 !important;
    margin: 0 !important;
}

a#nav-dropdown-dark-example.dropdown-toggle.nav-link {
    color: #7B84A8;
}

a#nav-dropdown.dropdown-toggle.nav-link {
    color: #7B84A8;
}


/*-----*/
* {
    margin: 0;
    padding: 0;
}

html,
body,
#Container {
    height: 100%;
    color: white;
}

#Container:before {
    content: '';
    height: 100%;
    float: left;
}


#Stretch {
    background-color: blue;
}

#Stretch:after {
    content: '';
    display: block;
    clear: both;
}

#Container,
#Container > div {
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

/*-----*/

@media screen and (max-width: 790px) {
   
    #growMe{
        width: 100%;
        padding: 0;
    }

    #hideMe{
        display: none;
    }
  
}

.navbar-row{
    display:flex;
    gap: 35px;
    
}

.navbar-row > div{
    padding: 0;
}

.navbar-right{
    display: flex;
    gap:35px;
}

.firsrow-dropdow{
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-left: 25px !important;
}


.firsrow-dropdow img{
    width: 100%; 
    height: auto;
}

.main-chart canvas{
    min-height: 230px;
}

.main-navbar{
    white-space: nowrap;
}

.my-account .dropdown-menu{
    background-color: rgb(31, 39, 72) !important;
    padding:17px 15px 5px 15px !important;
}

.my-account .dropdown-menu .dropdown-item{
    box-shadow: 0px 4px 5px  #21202082;
    border-radius: 7px;
    background-color: #262d51;
    color: rgb(123, 132, 168);
    margin-bottom:15px;
}

.my-account .dropdown-menu .dropdown-item p{
    /* text-shadow: 0px 4px 3px #00000096 */
    font-size: 14px;
    display: inline;
}

.my-account .dropdown-menu .dropdown-item:hover{
    background-color: #36d881;
    color: white;
}

.my-account .dropitem{
    display: flex;
    justify-content: space-between;
    gap:25px;
    align-items: center;
}


.my-account .dropdown-menu{
    margin-left: -97px !important;
    border-top: none;
}


.triangle-dropdown{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 10.5px 13px 10.5px;
    border-color: transparent transparent rgb(31, 39, 72) transparent;
    transform: rotate(0deg);
    position: absolute;
    right: 100px;
    top: -10px;
}

#notification-dropdown + .dropdown-menu {
  background-color: #171e3c;
}

.flexContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 90vh;
    gap:50px
}

.back-home{
    margin-top: 70px;
}

.back-home a{
    text-decoration: none;
    color:rgb(192 197 217);
    background-color: #262d51;
    padding: 10px 15px;
    box-shadow: 0px 4px 5px #21202082;
    border-radius: 7px;
}

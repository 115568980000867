.table-dark {
    --bs-table-striped-bg: #1a2141 !important;
    --bs-table-bg:#1d2445 !important;
    
    --bs-table-border-color: #1a2141 !important;
    --bs-table-hover-bg: #131932 !important;
    border-color: #1a2141 !important;
}

.table {
  
    border-spacing:0;
    border-collapse: collapse;
}

td{
    border:none !important;
}
.select-tag-info {
  background: #192141;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 12px 40px 12px 20px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.051));
  border-radius: 0.25rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: 96.5%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAJCAYAAADtj3ZXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABMSURBVHgBnYyLCQAgCEQdqQ1zs0ZpFEsqCPLbgSCe7wER9TkVEuF/5nhBWgkJNsjBcwgJHvAqTIEKegIX1ARhUBC0FCgIEH4ywWL1A+dKnRQz9bNNAAAAAElFTkSuQmCC) !important;
}
button.btn[type="Submit"], button.btn[type="next"], button.btn.greenbtn{
  position: relative;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #131833;
  background-color: #38d982!important;
  border-color: #38d982!important;
  border-radius: 10px;
  padding: 10px;
}
.change-group-info button.btn[type="next"],
.change-group-info button.btn[type="Submit"],
button.btn.greenbtn
 {
  padding: 6px 12px;
}
.change-group-info {
  padding-bottom: 0px;
  margin: auto;
  max-width: 780px;
}

button.btn[type="Submit"]:active, button.btn[type="next"]:active, button.btn.greenbtn:active {
  background-color: #38d982!important;
  border-color: #38d982!important;
}
.select-tag-info option {
  border-bottom: 1px solid rgba(49, 59, 101, 0.5) !important;
  padding: 15px 0px !important;
}
.mb-28{margin-bottom: 25px!important;}
.mb-24{margin-bottom: 24px!important;}
.mt-66px{margin-top:66px!important;}
.contentSecuirty {
  overflow-y: auto;
  background-size: cover;
  width: 42%;
  height: auto;
  margin: 0 auto;
  padding-top: 6rem;
}
@media screen and (max-width: 2200px) {
.container-scale-tab {
  transform: scale(0.75);
  height: 0;
}
}
.centerSearch{
  margin-left: auto;
  margin-right: auto;
}
.Regionname{
  color: #38d982;
}
.selectionRegion{
  font-size: 20px;
}
.mt-2{
  margin-top: 4px;
}

.quick-icon-img {
  filter: invert(74%) sepia(4%) saturate(1536%) hue-rotate(192deg) brightness(91%) contrast(98%);
}
.greenBack {
    background-color: #38D982 ;
    color: #056C34 !important ; 
}


.ProfileLeftNav {
    /* width: 90%; */
    height: 30%;
    /* margin: auto; */
    padding-left: 0px;
    
}
.ProfileLeftNav > li {
    padding: 12px;
    text-decoration: none;
    border-radius:0px 6px 6px  ;
    margin-bottom: 5px;
    list-style-position: inside;
    color: #8F96B4;
    cursor: pointer;

}
.ProfileLeftNav > li:hover {
    
   
    background-color: #38D982;
    color: #056C34;
    
    
}

#profileHeader {
    font-size: 16px;
    
    color: #7B84A8;
    cursor:default;
    
    
}
#profileHeader > hr {

    background-color: #313B65;
    height: 3px;
    margin-left: -14px;
   
}

#ProfileHr {
    height: 1px;
    background: #313b65;
    border-color: #313b65;
}

.ProfileLeftNav > li::marker {
    color:white;
}